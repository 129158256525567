import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import {
    Button,
    Col, ConnectedPlusIcon,
    Popup,
    Row,
    SkubotLogo,
    StateDropdown,
    StatusDropdown, Table, TBody, TD, TH,
    Theme, TR,
    XIcon
} from "@commonsku/styles";
import {formatDate, oauth, parseMysqlDate} from "../utils";

const toArray = (v) => {
  return _.isArray(v) ? v : [v];
};

const checkOrderStatus = async (purchase_order_id, division_id, reference_number,) => {
  const data = {
    'purchase_order_id': purchase_order_id,
    'division_id': division_id,
    'reference_number': reference_number,
    'query_type': 1,
    'action': 'order_status'
  };

  const response = await oauth('POST', 'promo-standards', data);
  const { status, ship_notification } = response.json || {};
  const statuses = {};
  (toArray((status || {}).order_status || [])).forEach((os) => {
    return toArray(_.get(os, 'OrderStatusDetailArray.OrderStatusDetail') || []).forEach((osd) => {
      statuses[osd.factoryOrderNumber] = osd;
    });
  });
  return {
    statuses: _.values(statuses),
    notifications: toArray(_.get(ship_notification, 'SalesOrderArray.SalesOrder') || []),
  };
};

const StyledTHead = styled.thead`
    &&& {
        tr:first-child th
        {
            background-color: #edf4f7;
        }
    }
`;

const Address = ({ address }) => {
  const {
    address1, address2, address3, address4, city, region, postalCode, country
  }  = address;
  return <>{
    _.flatMap(
      _.filter([address1, address2, address3, address4, _.filter([city, region, postalCode, country]).join(' ')]),
      (line) => [line, <br/>]
    )
  }</>;
};

function OrderStatusPopup({data, tableStandings, tableStatuses, recommendation, commonskuStatuses, onChange, statusIcon, onClose }) {
    const [statuses, setStatuses] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const { purchase_order_id, division_id, form_number } = data;

    useEffect(() => {
      checkOrderStatus(purchase_order_id, division_id, form_number).then((result) => {
        setStatuses(result.statuses);
        setNotifications(result.notifications);
      });
    }, [purchase_order_id, division_id, form_number]);

    let timeFormat = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    const renderStatus = (value, i) => {

        let expectedShipDate = value.expectedShipDate ? new Date(value.expectedShipDate).toLocaleTimeString('en-us', timeFormat) : 'N/a';
        let expectedDeliveryDate = value.expectedDeliveryDate ? new Date(value.expectedDeliveryDate).toLocaleTimeString('en-us', timeFormat) : 'N/a';
        let updatedAt = value.validTimestamp ? new Date(value.validTimestamp).toLocaleDateString('en-us', timeFormat) : 'N/a';

        return (
            <TR key={i} style={{ borderBottom: '1px solid #d7d7d7', background: '#FFF' }}>
                <TD style={{paddingLeft:'7px'}}>{value.factoryOrderNumber}</TD>
                <TD>{value.statusName}</TD>
                <TD>{expectedShipDate}</TD>
                <TD>{expectedDeliveryDate}</TD>
                <TD>{
                  _.map(toArray(_.get(value, 'ResponseToArray.RespondTo') || []), (responder) => {
                    return _.flatMap(
                      _.filter([responder.name, responder.emailAddress, responder.phoneNumber]),
                      (i) => [i, <br/>]
                    );
                  }) || 'N/a'
                }</TD>
                <TD>{value.additionalExplanation ? value.additionalExplanation : 'N/a'}</TD>
                <TD>{value.responseRequired}</TD>
                <TD>{updatedAt}</TD>
            </TR>
        );
    };

    const renderNotifications = (value, i) => {
        const locations = toArray(_.get(value, 'ShipmentLocationArray.ShipmentLocation') || []);

        return (
            <TR key={i} style={{ borderBottom: '1px solid #d7d7d7' }}>
                <TD style={{paddingLeft:'7px'}}>{value.salesOrderNumber}</TD>
                <TD>{value.complete}</TD>
                <TD>{
                  _.map(locations, (location, i) => {
                    return <Address key={i} address={location.ShipFromAddress}/>;
                  })
                }</TD>
                <TD>{
                  _.map(locations, (location, i) => {
                    return <Address key={i} address={location.ShipToAddress}/>;
                  })
                }</TD>
                <TD>{
                  _.map(locations, (location, i) => {
                    return _.map(toArray(_.get(location, 'PackageArray.Package') || []), 'trackingNumber').flatMap((line) => [
                      line, <br/>
                    ]);
                  })
                }</TD>
              <TD>{
                _.map(locations, (location, i) => {
                  return _.map(toArray(_.get(location, 'PackageArray.Package') || []), 'shipmentDate').flatMap((shipmentDate) => [
                    shipmentDate ? new Date(shipmentDate).toLocaleTimeString('en-us', timeFormat) : 'N/a', <br/>
                  ]);
                })
              }</TD>
                <TD>{
                  _.map(locations, (location, i) => {
                    return _.map(toArray(_.get(location, 'PackageArray.Package') || []), 'shipment_method').flatMap((line) => [
                      line, <br/>
                    ]);
                  })
                }</TD>
            </TR>
        );
    };

    return (
        <Theme>
            <Popup
                style={{ padding: '5px 25px', xoverflowY: 'scroll'  }}
                onClose={onClose}
                title={[<span style={{fontWeight: '600', fontSize:'24px', fontFamily: 'TT Norms Pro'}}>Order Status & Shipment Notification </span>, <span style={{fontWeight: '400', fontSize:'16px', fontFamily: 'TT Norms Pro'}}>PO#{data.form_number}</span>]}
                controls={<XIcon onClick={onClose} style={{background:'#FFF', color: '#00A0B6'}}/>}>
                <div className="row" style={{ background: '#edf4f7', paddingTop: '16px'}}>
                    {statuses.length === 0 ? (
                    <div className="row popup-content column">
                        <div className="small-12 columns">
                            <div className="small-12 text-center"><br/><img src="/images/gears.gif"/><br/></div>
                        </div>
                    </div>
                ) :(<>
                    <StatusTile style={{float: 'left', marginRight: '16px', marginLeft: '16px'}}>
                        <Row style={{height:'64px'}}>
                            <Col style={{color: '#2A4D63', }}>
                                <div style={{lineHeight: '20px', textAlign: 'left', marginLeft: '20px'}}><SkubotLogo
                                    style={{display: 'inline-block', marginRight: '20px'}}
                                    color={'#123952'}/>
                                    <div style={{
                                        display: 'inline-block',
                                        width: '70px',
                                        fontWeight: '600',
                                        verticalAlign: 'top',
                                        paddingTop: '23px',
                                        color: '#123952'
                                    }}>Commonsku
                                    </div>
                                </div>
                                </Col>
                            <Col>
                                {recommendation &&
                                    <Button
                                        style={{marginLeft: '20px', fontWeight: 'bold'}}
                                        size="small"
                                        onClick={(e)=> {
                                         if (recommendation.field === 'standing') {
                                             onChange(data.purchase_order_id, recommendation.field, data.standing)(recommendation.value);
                                         } else {
                                             const new_status_id = commonskuStatuses.find((status) => status.label === recommendation.value).value;
                                             onChange(data.purchase_order_id, 'status_id',data.status_id)(new_status_id);
                                         };
                                     }}
                                 >
                                     Set {recommendation.label} to
                                     "{recommendation.value}"
                                 </Button>}
                             </Col>
                        </Row>
                        <Row style={{marginTop: '20px'}}>
                            <Col style={{paddingLeft: '10px'}}>
                                <StatusDropdown
                                    style={{width: '79px', float: 'left'}}
                                    items={tableStandings}
                                    value={_.find(tableStandings, {value: data.standing})}
                                    row={data}
                                />
                                <StateDropdown
                                    style={{width: '112px', marginLeft: '100px'}}
                                    items={_.filter(tableStatuses, ({ order, content }) => {
                                        return order > 0 || content === data.status_name;
                                    })}
                                    value={_.find(tableStatuses, { content: data.status_name })}
                                    row={data}
                                    scrollContentHeight="300px"
                                    maxCircles={tableStatuses.length - 2}
                                />
                            </Col>
                            <Col>
                                <p style={{textAlign: 'left', fontWeight: '400', fontSize:'14px', fontFamily: 'TT Norms Pro'}}>Click on the commonsku status and stage to change them, or click the button above to automatically set the commonsku fields.</p>

                            </Col>
                        </Row>
                        <Row  style={{marginTop: '15px'}}>
                            <Col style={{textAlign: 'left', paddingLeft: '10px'}}>
                                <span style={{fontWeight: '600', fontSize:'16px', fontFamily: 'TT Norms Pro'}}>PO Shipping Date:</span>
                                <span style={{marginLeft: '10px'}}>{data.date_shipped && data.date_shipped.toString().match(/^\d+$/) ? formatDate(data.date_shipped, true) : data.date_shipped && data.date_shipped.toString().match(/^\d+-\d+-\d+ \d+:\d+:\d+$/) ? parseMysqlDate(data.date_shipped) : null}</span>
                            </Col>
                            <Col></Col>
                        </Row>
                    </StatusTile>
                    <StatusTile style={{ marginRight: '16px'}}>
                        <Row style={{marginLeft: '10px'}}>
                            <Col>
                                <div style={{  lineHeight: '20px', textAlign:'left' }}><ConnectedPlusIcon style={{display:'inline-block', marginRight:'20px'}} size={'large'} color={'#123952'}/><div style={{display:'inline-block', width:'70px', fontWeight: '600',verticalAlign: 'top', paddingTop:'7px', color:'#123952'}}>Connected</div></div>
                            </Col>
                            <Col>
                                <div style={{display: 'inline-block'}}>
                                    <a style={{textDecoration: 'none', color: '#00A0B6', fontWeight: '600', fontSize:'12px', fontFamily: 'TT Norms Pro'}} href="https://commonsku.com/integrations/promostandards" target={"_blank"}>How does it work</a>
                                </div>
                                <Button
                                  style={{marginLeft: '20px', fontWeight: 'bold', display: 'inline-block'}}
                                  size="small" onClick={() =>{
                                    checkOrderStatus(purchase_order_id, division_id, form_number).then((result) => {
                                      setStatuses(result.statuses);
                                      setNotifications(result.notifications);
                                    });
                                  }}
                                >Refresh</Button>
                            </Col>
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Col style={{textAlign:'left', marginLeft: '15px'}}>{statusIcon} <span style={{fontWeight: '600',verticalAlign: 'top', paddingTop:'7px', color:'#123952', marginLeft: '20px'}}>{data.ps_status_name}</span></Col>
                        </Row>
                        <Row style={{lineHeight:'50px'}}>
                            <Col style={{textAlign:'left', marginLeft: '10px'}}>
                                <div>
                                    Updated {moment(data.date_valid).isValid() && <span>{parseInt(moment.duration(moment().diff(moment(data.date_valid))).asHours())}h ago</span>}
                                </div>
                            </Col>
                        </Row>

                        <Row style={{lineHeight:'50px'}}>
                            <Col style={{textAlign:'left', marginLeft: '10px'}}>
                                {
                                    statuses && statuses.map((value, i) => {
                                        return  value.additionalExplanation && <div>{value.additionalExplanation}</div>
                                    })
                                }
                            </Col>
                        </Row>

                        <Row style={{lineHeight:'50px'}}>
                            <Col style={{textAlign:'left', marginLeft: '10px'}}>
                                <span style={{fontWeight: '600', fontSize:'16px', fontFamily: 'TT Norms Pro'}}>Suggested Ship Date:</span>
                                {
                                    statuses && statuses.map((value, i) => {
                                        return  value.expectedShipDate && <span style={{marginLeft: '10px'}}>{moment(value.expectedShipDate).isValid() ? moment(value.expectedShipDate).format('MMMM D, YYYY h:m A') : 'n/a'}</span>
                                    })
                                }
                            </Col>
                        </Row>
                    </StatusTile>
                        <Table style={{marginTop: '10px'}}>
                            <StyledTHead>
                                <TR>
                                    <TH style={{paddingLeft: '7px'}}>Factory Order Number</TH>
                                    <TH>Status Name</TH>
                                    <TH>Expected Ship Date</TH>
                                    <TH>Expected Delivery Date</TH>
                                    <TH>Respond To</TH>
                                    <TH>Additional Explanation</TH>
                                    <TH>Response Required</TH>
                                    <TH>Updated At</TH>
                            </TR>
                        </StyledTHead>
                        <TBody>
                            {statuses && statuses.map((s, i) =>
                                renderStatus(s, i)
                            )}
                        </TBody>
                    </Table>
                        <Table style={{paddingTop: '10px'}}>
                            <StyledTHead>
                                <TR >
                                    <TH style={{paddingLeft:'7px'}}>Factory Order Number</TH>
                                    <TH>Complete</TH>
                                    <TH>Shipping From</TH>
                                    <TH>Shipping To</TH>
                                    <TH>Tracking Number</TH>
                                    <TH>Shipment Date</TH>
                                    <TH>Shipment Method</TH>
                                </TR>
                            </StyledTHead>
                            <TBody>
                                {notifications && notifications.map((s, i) =>
                                    renderNotifications(s, i)
                                )}
                            </TBody>
                        </Table>
                </>)}
                </div>
            </Popup>
        </Theme>
    );
}

const StatusTile = ({children, style={}}) => {
    return <div
        style={{
            textAlign: 'center',
            background: '#FFFFFF',
            boxShadow: '0px 4px 5px rgba(72, 78, 86, 0.1)',
            overflowX: 'auto',
            flexDirection: 'column',
            overflowY: 'hidden',
            width: '48.5%',
            height: '260px',
            borderRadius: '16px',
            paddingTop: '32px',
            ...style,
        }}
    >
        {children}
    </div>
};

export default OrderStatusPopup;
