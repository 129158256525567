import _ from 'lodash';
import React from 'react';

import { toTitleCase } from '../../utils';

export default function StatePanel({
  users, suppliers, clients,
  statuses, ship_methods, proof_statuses, next_action_types,
  poNumber, soNumber, projectNumber,
  orderRepId, clientRepId, productionRepId,
  supplierId, clientId, shipMethodId, proofStatusId,
  standing, statusId, firm, nextActionTypeId,
  dateType, startDate, endDate, dateCategory,
}) {
  const dateTypeOptions = [
    { value: 'in_hands_date', label: 'In Hands Date'},
    { value: 'ship_date', label: 'Ship Date'},
    { value: 'next_action_date', label: 'Next Action Date'}
  ];
  const descriptions = [];
  if(projectNumber) {
    descriptions.push(`Project #: <strong>${projectNumber}</strong>`);
  }
  if(poNumber) {
    descriptions.push(`PO #: <strong>${poNumber}</strong>`);
  }
  if(soNumber) {
    descriptions.push(`SO #: <strong>${soNumber}</strong>`);
  }
  if(clientRepId) {
    const clientRep = _.find(users, { value: clientRepId });
    descriptions.push(`Client Rep: <strong>${clientRep ? clientRep.label : 'commonsku'}</strong>`);
  }
  if(orderRepId) {
    const orderRep = _.find(users, { value: orderRepId });
    descriptions.push(`Order Rep: <strong>${orderRep ? orderRep.label : 'commonsku'}</strong>`);
  }
  if(productionRepId) {
    const productionRep = _.find(users, { value: productionRepId });
    descriptions.push(`Production Rep: <strong>${productionRep ? productionRep.label : 'commonsku'}</strong>`);
  }
  if(supplierId) {
    const supplier = _.find(suppliers, { value: supplierId });
    supplier && descriptions.push(`Supplier: <strong>${supplier.label}</strong>`);
  }
  if(clientId) {
    const client = _.find(clients, { value: clientId });
    client && descriptions.push(`Client: <strong>${client.label}</strong>`);
  }
  if(shipMethodId) {
    const shipMethod = _.find(ship_methods, { value: shipMethodId });
    shipMethod && descriptions.push(`Shipping Method: <strong>${shipMethod.label}</strong>`);
  }
  if(proofStatusId) {
    const proofStatus = _.find(proof_statuses, { value: proofStatusId });
    proofStatus && descriptions.push(`Proof Status: <strong>${proofStatus.label}</strong>`);
  }
  if(standing) {
    descriptions.push(`Status: <strong>${toTitleCase(standing)}</strong>`);
  }
  if(statusId) {
    const status = _.find(statuses, { value: statusId });
    status && descriptions.push(`Stage: <strong>${status.label}</strong>`);
  }
  if(firm === '1' || firm === '0') {
    descriptions.push(`Firm: <strong>${firm === '1' ? 'Yes' : 'No'}</strong>`);
  }
  if(nextActionTypeId) {
    const next_action_type = _.find(next_action_types, { value: nextActionTypeId });
    next_action_type && descriptions.push(`Next Action: <strong>${next_action_type.label}</strong>`);
  }
  if(dateType) {
    const date_type = _.find(dateTypeOptions, { value: dateType });
    const dateString = dateCategory === 'custom' ? `${startDate} - ${endDate}` :
      dateCategory === 'overdue' ? dateCategory : `Next ${dateCategory} days`
    ;
    descriptions.push(`${date_type.label}: <strong>${dateString}</strong>`);
  }
  const description = descriptions.length > 0 ? descriptions.join(', ') : 'No search criteria set';
  return (
    <div dangerouslySetInnerHTML={{__html: description }} style={{ marginBottom: '10px' }}></div>
  );
}
