import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, LabeledInput, LabeledSelect, Select } from '@commonsku/styles';

import DateInput, { CSKUInput } from '../DateInput';

import ParentSizer from '../../hooks/ParentSizer';

export default function Filter({
  users, suppliers, clients, statuses, ship_methods,
  proof_statuses, next_action_types, filters, onChange, onSubmit, type = ''
}) {
  const {
    poNumber, soNumber, projectNumber, orderRepId, clientRepId, productionRepId,
    supplierId, clientId, shipMethodId, proofStatusId, standing, statusId, firm,
    nextActionTypeId, dateType, startDate, endDate, dateCategory,
  } = filters;
  const firmOptions = [
    { value: '', label: 'All' },
    { value: '1', label: 'Yes' },
    { value: '0', label: 'No' }
  ];
  const standingOptions = [
    { value: '', label: 'All' },
    { value: 'OK', label: 'OK'},
    { value: 'PROBLEM', label: 'Problem'},
    { value: 'FOLLOWUP', label: 'Follow Up'}
  ];
  const dateTypeOptions = [
    { value: '', label: 'All' },
    { value: 'in_hands_date', label: 'In Hands Date'},
    { value: 'ship_date', label: 'Proj Ship Date'},
    { value: 'next_action_date', label: 'Next Action Date'}
  ];
  const dateCategoryOptions = [
    { value: 'custom', label: 'Custom'},
    { value: '0', label: 'Today'},
    { value: '7', label: 'Next 7 Days'},
    { value: '14', label: 'Next 14 Days'},
    { value: 'overdue', label: 'Overdue'},
  ];

  const [state, setState] = useState({ poNumber, soNumber, projectNumber });
  useEffect(() => {
    setState({
      ...state,
      poNumber, soNumber, projectNumber,
    });
  }, [poNumber, soNumber, projectNumber]);

  const handleEnter = (callback) => (e) => {
    if (e.key === 'Enter') {
      callback(e);
    }
  };

  return <ParentSizer
    onKeyDown={handleEnter((e) => {
      onSubmit({
        ...filters, ...state
      });
    })}
    render={(layout) => {
      const itemWidth = layout.width > 1024
        ? (layout.width - 50) / 5
          : layout.width > 640
            ? (layout.width - 30) / 3
              : layout.width;

      const itemStyle= { width: itemWidth, paddingRight: '10px' };

      return <>
        {type === 'Lookup' && <Row>
          <div style={itemStyle}>
            <LabeledInput
              label="Purchase Order"
              value={state.poNumber}
              placeholder="Purchase Order"
              onChange={e => setState({ ...state, poNumber: e.target.value })}
              onBlur={() => onChange('poNumber', state.poNumber)}
              onKeyDown={handleEnter((e) => onChange('poNumber', state.poNumber))}
            />
          </div>
          <div style={itemStyle}>
            <LabeledInput
              label="Sales Order"
              value={state.soNumber}
              placeholder="Sales Order"
              onChange={e => setState({ ...state, soNumber: e.target.value })}
              onBlur={() => onChange('soNumber', state.soNumber)}
              onKeyDown={handleEnter((e) => onChange('soNumber', state.soNumber))}
            />
          </div>
          <div style={itemStyle}>
            <LabeledInput
              label="Project"
              value={state.projectNumber}
              placeholder="Project #"
              onChange={e => setState({ ...state, projectNumber: e.target.value })}
              onBlur={() => onChange('projectNumber', state.projectNumber)}
              onKeyDown={handleEnter((e) => onChange('projectNumber', state.projectNumber))}
            />
          </div>
        </Row>}
        {type !== 'Lookup' && <Row>
          <div style={itemStyle}>
            <LabeledSelect key={`client_rep_${clientRepId}`} label="Client Rep" name="client_rep" noMargin
              value={_.find(users, { value: clientRepId })} options={users}
              onChange={e => onChange('clientRepId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`order_rep_${orderRepId}`}
              label="Order Rep" name="order_rep" noMargin
              value={_.find(users, { value: orderRepId })} options={users}
              onChange={e => onChange('orderRepId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`production_rep_${productionRepId}`} label="Production Rep" name="production_rep" noMargin
              value={_.find(users, { value: productionRepId })} options={users}
              onChange={e => onChange('productionRepId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`supplier_${supplierId}`} label="Supplier" name="supplier" noMargin
              value={_.find(suppliers, { value: supplierId })} options={suppliers}
              onChange={e => onChange('supplierId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`client_${clientId}`} label="Client" name="client" noMargin
              value={_.find(clients, { value: clientId })} options={clients}
              onChange={e => onChange('clientId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`shipping_method_${shipMethodId}`} label="Shipping Method" name="shipping_method" noMargin
              value={_.find(ship_methods, { value: shipMethodId })}
              options={ship_methods}
              onChange={e => onChange('shipMethodId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`proof_status_${proofStatusId}`} label="Proof Status" name="proof_status" noMargin
              value={_.find(proof_statuses, { value: proofStatusId })}
              options={proof_statuses}
              onChange={e => onChange('proofStatusId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`standing_${standing}`} label="Status" name="standing" noMargin
              value={_.find(standingOptions, { value: standing})} options={standingOptions}
              onChange={e => onChange('standing', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`stage_${statusId}`} label="Stage" name="stage" noMargin
              value={_.find(statuses, { value: statusId })} options={statuses}
              onChange={e => onChange('statusId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`firm_${firm}`} label="Firm" name="firm" noMargin
              value={_.find(firmOptions, { value: firm })} options={firmOptions}
              onChange={e => onChange('firm', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`next_action_type_${nextActionTypeId}`} label="Next Action" name="next_action_type" noMargin
              value={_.find(next_action_types, { value: nextActionTypeId })}
              options={next_action_types}
              onChange={e => onChange('nextActionTypeId', e.value)}
            />
          </div>
          <div style={itemStyle}>
            <LabeledSelect key={`date_type_${dateType}`} label="Date Type" name="date_type" noMargin
              value={_.find(dateTypeOptions, { value: dateType })} options={dateTypeOptions}
              onChange={e => onChange('dateType', e.value)}
            />
            {dateType && <div style={{ marginTop: 16 }}><Select
              value={_.find(dateCategoryOptions, { value: dateCategory })}
              options={dateCategoryOptions}
              onChange={e => onChange('dateCategory', e.value)}
            /></div>}
          </div>
          {dateType && dateCategory === 'custom' && <div style={{ ...itemStyle, marginTop: 24 }}>
            <DateInput
              customInput={<CSKUInput />}
              showMonthDropdown
              showYearDropdown
              placeholder="Start Date"
              value={startDate}
              onChange={date => onChange('startDate', date)}
            />
            <DateInput
              customInput={<CSKUInput />}
              showMonthDropdown
              showYearDropdown
              placeholder="End Date"
              value={endDate}
              onChange={date => onChange('endDate', date)}
            />
          </div>}
        </Row>}
      </>;
    }}
  />;
}
