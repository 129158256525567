import { map } from 'lodash';
import React, { Fragment } from 'react';
import { Col, Row, TabBar, Tab } from '@commonsku/styles';
import ReactTooltip from 'react-tooltip';

import { truncate } from '../../utils';

export default function renderTabs({ report_views, loading, selectedTab, setSelectedTab }) {
  return <Row id="production-tabs">
    <Col xs={12}>
      <TabBar>
        {map(report_views, (report_view, index) => <Fragment key={index}>
          {report_view.name.length >= 20 ? <ReactTooltip id={`tab_${index}`} className="tab-tooltip" type="info" place="bottom" /> : null}
          <Tab key={index}
            selected={index == selectedTab}
            onClick={() => {
              if(loading) {
                return false;
              }
              setSelectedTab(index);
            }}
            style={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}
          >
            <span data-tip={report_view.name} data-for={`tab_${index}`}
              //onMouseEnter={() => setHoveringTab(tab.key)}
              //onMouseLeave={() => setHoveringTab(null)}
            >{truncate(report_view.name, 20)}</span>
            {/* hoveringTab === tab.key && tab.label.length > 20 ?
              <span style={{
                width: '150px',
                backgroundColor: '#fff',
                color: 'black',
                textAlign: 'center',
                borderRadius: '6px',
                padding: '5px 10px',
                position: 'absolute',
                top: '45px',
                left: '15px',
                border: '1px solid #E2E6EF',
                boxShadow: '0px 1px 5px #2d394026',
                zIndex: 100
              }}>{tab.label}</span>
            : null */}
          </Tab>
        </Fragment>)}
      </TabBar>
    </Col>
  </Row>;
}