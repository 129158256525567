import React, { useLayoutEffect, useState, useRef } from 'react';

export default function ParentSizer({ render, ...props }) {
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const ref = useRef(null);
  useLayoutEffect(() => {
    function updateSize() {
        if (ref.current) {
            const {
                offsetLeft: x,
                offsetTop: y,
                scrollWidth: width,
                scrollHeight: height,
            } = ref.current;
            setLayout({ x, y, width, height });
        }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <div ref={ref} {...props}>
      {render(layout)}
    </div>
  );
}