import { map } from 'lodash';
import React from 'react';
import { Col, Row, Dropdown, LabeledCheckbox } from '@commonsku/styles';

export default function ColumnSelector({ columns, onChange, mouseLeaveCallback }) {
  return (
    <Dropdown
      openMenu={true}
      icon={<div>&nbsp;</div>}
      mouseLeaveCallback={mouseLeaveCallback}>
      <Row>
        <Col xs={12}>
          {map(columns, (column, key) => {
            const { label, value } = column;
            return <LabeledCheckbox key={key} label={label} checked={value} onChange={() => {
              onChange(key, !value);
            }}/>;
          })}
        </Col>
      </Row>
    </Dropdown>
  );
}