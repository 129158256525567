import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Theme, Popup, LabeledInput } from '@commonsku/styles';

import { saveReportView, updateReportView } from '../../actions';

export default function SaveViewPopup({
  onClose, filter, sortDirectionDivRef, currentColumnsDivRef, report_view, onSave,
}) {
  const [name, setName] = useState(report_view.is_default == 1 ? '' : report_view.name);
  const [sortDirection, setSortDirection] = useState({});
  const [columns, setColumns] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const sortData = JSON.parse(sortDirectionDivRef.current.innerText);
    setSortDirection(sortData);
    const columnData = JSON.parse(currentColumnsDivRef.current.innerText);
    setColumns(columnData);
  }, []);

  function handleSave() {
    if(name === '') {
      return;
    }
    let currentFilter = {...filter};
    _.each(currentFilter, function(f, i) {
      if(!f) {
        delete currentFilter[i];
      }
    });
    let view = [];
    _.each(columns, function(column) {
      let col = {
        accessor: column,
        sortDirection: null
      };
      if(!_.isEmpty(sortDirection) && column === sortDirection.accessor) {
        col.sortDirection = sortDirection.direction;
      }
      view.push(col);
    });
    if(report_view.is_default == 1) {
      dispatch(saveReportView('PURCHASE ORDER', name.trim(), JSON.stringify(currentFilter), JSON.stringify(view)))
        .then(onSave);
    }else{
      dispatch(updateReportView(report_view.report_view_id, name.trim(), JSON.stringify(currentFilter), JSON.stringify(view)))
        .then(onSave);
    }
    onClose();
  }

  return (
    <Theme>
      <Popup
        style={{ padding: '5px 25px' }}
        width="30%"
        height="30%"
        onClose={onClose}
        title="Save View"
        controls={
          <>
            <Button cta onClick={handleSave}>Save</Button>
            <Button onClick={onClose}>Close</Button>
          </>
        }
      >
          <div className="row" style={{ overflow: 'auto' }}>
            <LabeledInput
              label="Name"
              value={name}
              placeholder="Name"
              onChange={e => setName(e.target.value)}
            />
          </div>
      </Popup>
    </Theme>
  );
}
